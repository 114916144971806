import slugify from 'slugify'

const ataCodes = [385, 397, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 424, 427, 428, 429, 430, 398, 415, 416, 417, 425, 399, 418, 419, 420, 421, 422, 423, 426, 431]
const isInformativo = 392
const isLivro = 380
const isJornal = 379
const isInternet = 391

export const coreSedeGeral = 1
export const regionSedeGeral = 1
export const agencySedeGeral = 1
export const agencyDiretoriageral = 2
export const agencyRegion = 3
export const agencyCoreDAV = 4
export const agencyLocal = 265

export const hasDocType = ({ documentTypeId }) => Boolean(documentTypeId)
export const hasLink = (doc) => isCarta(doc) || [isInternet, isInformativo, isLivro, isJornal].includes(doc.documentTypeId)
export const hasSubType = (doc) => isBoletim(doc) || isCarta(doc) || [isLivro, isInformativo].includes(doc.documentTypeId)

export const definedClassWithNumeration = (doc) => {
  if (!hasNumeration(doc)) return 'col-sm-12'
  return isBoletim(doc) ? 'col-sm-4' : 'col-sm-8'
}

export const hasNumeration = (doc) => isBoletim(doc) || isCarta(doc)
export const hasDescription = (doc) => doc.documentTypeId && !isBoletim(doc)

export const isBoletim = (doc) => doc.documentTypeId === 382
export const isCarta = (doc) => doc.documentTypeId === 394

export const isBoletimDesignacao = (doc) => isBoletim(doc) && doc.documentSubTypeId === 3821
export const isAgencyRegion = (doc) => [agencyLocal, agencyCoreDAV, agencyRegion].includes(doc.agencyId)
export const isAgencyCoreDav = (doc) => [agencyLocal, agencyCoreDAV].includes(doc.agencyId)
export const isAgencySGDG = (doc) => [agencySedeGeral, agencyDiretoriageral].includes(doc.agencyId)

export const definedLabelSubType = (doc) => {
  if (isBoletim(doc)) return 'Tipo do Boletim'
  if (isCarta(doc)) return 'Tipo da Carta'

  switch (doc.documentTypeId) {
    case isLivro: return 'Tipo do Livro'
    case isInformativo: return 'Tipo do Informativo'
  }

  return 'Tipo'
}

export const definedLabelNumeration = (doc) => {
  if (isBoletim(doc)) return 'Número do Boletim'
  if (isCarta(doc)) return 'Número da Carta'
  return 'Número'
}

export const definedLabelDescription = (doc) => {
  if (ataCodes.includes(doc.documentTypeId)) return 'Título da Ata'
  if (isCarta(doc)) return 'Assunto da Carta'

  switch (doc.documentTypeId) {
    case isJornal: return 'Título da matéria'
    case isInternet: return 'Título da matéria'
    case isLivro: return 'Nome do Livro'
    case isInformativo: return 'Nome do Informativo'
  }

  return 'Assunto'
}

export const definedLabelDate = (doc) => {
  if (ataCodes.includes(doc.documentTypeId)) return 'Data da Ata'
  if (isBoletim(doc)) return 'Data do Boletim'
  if (isCarta(doc)) return 'Data da Carta'
  if (doc.documentTypeId === isInformativo) return 'Data do Informativo'

  return 'Data do Documento'
}

export const genUrl = ({ documentTypeName, id }, withHost = true) => {
  const docPath = `${toSlug(documentTypeName)}/${id}`
  return `${withHost ? window.location.origin : ''}/feeds/${docPath}`
}

export const toSlug = (str) => {
  const cleanedStr = String(str || '')
    .replace(/[ª/\\]/g, '-')
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .trim()

  return slugify(cleanedStr, { lower: true }).replace(/-+/g, '-')
}

const thumbStyle = {
  borderRadius: '3px',
  backgroundColor: '#027be3',
  opacity: 0.75
}

const barStyle = {
  borderRadius: '3px',
  backgroundColor: '#027be3',
  opacity: 0.2
}

const horizontalThumbStyle = { ...thumbStyle, height: '3px' }
const horizontalBarStyle = { ...barStyle, height: '5px' }
const verticalThumbStyle = { ...thumbStyle, width: '3px' }
const verticalBarStyle = { ...barStyle, width: '5px' }

export const leftBarScroll = { ...verticalBarStyle, left: 0 }
export const rightBarScroll = { ...verticalBarStyle, right: 0 }
export const topBarScroll = { ...horizontalBarStyle, top: 0 }
export const bottomBarScroll = { ...horizontalBarStyle, bottom: 0 }

export const leftThumbScroll = { ...verticalThumbStyle, left: 0 }
export const rightThumbScroll = { ...verticalThumbStyle, right: 0 }
export const topThumbScroll = { ...horizontalThumbStyle, top: 0 }
export const bottomThumbScroll = { ...horizontalThumbStyle, bottom: 0 }
