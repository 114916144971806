<template>
  <span class="">
    <CalendarIcon
      width="20"
      v-if="hasSchedule"
    />
  </span>
</template>

<script>
import CalendarIcon from '@/assets/icons/Calendar.svg'

export default {
  components: {
    CalendarIcon
  },

  props: {
    row: {},
    col: {}
  },

  computed: {
    hasSchedule: function () {
      const values = this.row.scheduleAt
      return (!!values)
    }
  }
}
</script>
