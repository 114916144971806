<template>
  <FieldMultiSelect
    :label="label"
    :state="state"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"

    :placeholder="placeholder"

    v-model="selected"

    :use-input="true"
    :allow-add-options="false"

    :option-value="optionValue"
    :option-label="optionLabel"
    :option-description="optionDescription"

    :load-options="loadOptions"
  />
</template>

<script>
import FieldMultiSelect from '@/components/forms/FieldMultiSelect'

export default {
  components: {
    FieldMultiSelect
  },

  props: {
    value: {},

    optionValue: {
      type: String,
      default: 'id'
    },

    optionLabel: {
      type: String,
      default: 'name'
    },

    optionDescription: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    extraName: {},
    extraField: {},

    state: Object,
    label: String,
    isRequired: Boolean,
    classLabel: String,
    apiOptions: Function
  },

  data () {
    return {
      selected: toArray(this.value)
    }
  },

  computed: {
    placeholder () {
      const hasValue = Array.isArray(this.selected) && this.selected.length
      return hasValue ? '' : 'Selecionar'
    }
  },

  methods: {
    loadOptions (val) {
      const vue = this
      const params = { $: val, limit: 9 }
      const hasExtraValue = vue.extraName && vue.extraField

      if (hasExtraValue) {
        const extraValue = Array.isArray(vue.extraField) ? vue.extraField.map((extra) => extra?.id ?? extra).join(',') : vue.extraField
        params[vue.extraName] = extraValue
      }

      return vue.apiOptions(params).then(({ rows }) => rows)
    }
  },

  watch: {
    selected (selected) {
      let input = toArray(selected)
      if (!input.length) input = null

      let vModel = toArray(this.value)
      if (!vModel.length) vModel = null

      if (isEqual(input, vModel)) return

      this.$emit('input', input)
    },

    value (value) {
      const vue = this

      let vModel = toArray(value)
      if (!vModel.length) vModel = null

      let selected = toArray(vue.selected)
      if (!selected.length) selected = null

      if (isEqual(vModel, selected)) return

      vue.selected = vModel
    }
  }
}

const toArray = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : []
}

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
