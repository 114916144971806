<template>
  <FieldWrapper
    :label="label"
    :state="state"
    :is-required="isRequired"
    :class-label="classLabel"
  >
    <InputDate
      v-model="timestamp"
      :placeholder="placeholder"
      :disabled="disabled"
      type="date"
      format="DD/MM/YYYY"
    />
  </FieldWrapper>
</template>

<script>
import InputDate from './InputDate'
import FieldWrapper from '@/components/forms/FieldWrapper'

export default {
  components: {
    InputDate,
    FieldWrapper
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    }
  },

  data () {
    return {
      timestamp: this.value
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        this.timestamp = value
      }
    },

    timestamp: {
      deep: true,
      handler (timestamp) {
        this.$emit('input', timestamp)
      }
    }
  }
}

</script>
