
export const getThumbnailFeed = (feed, size) => {
  const images = Array.isArray(feed.images) ? feed.images : []
  for (const image of images) {
    const resizes = Array.isArray(image.resizes) ? image.resizes : []
    const resize = resizes.find(({ name }) => name === size)
    if (resize) return resize.url
  }

  const attachments = Array.isArray(feed.attachments) ? feed.attachments : []
  for (const attach of attachments) {
    const resizes = Array.isArray(attach.resizes) ? attach.resizes : []
    const resize = resizes.find(({ name }) => name === size)
    if (resize) return resize.url
  }

  return null
}
