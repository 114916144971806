<template>
  <q-page :style-fn="myTweak">
    <div
      class="row q-mt-sm q-mx-auto max-container-width"
      :style="`height: ${this.contentHeight ? `${this.contentHeight}px` : '40vh'}`"
    >
      <div
        class="col-12 column scroll q-pl-sm q-pr-xs"
        :style="`max-width: 340px; max-height: ${this.contentHeight ? `${this.contentHeight}px` : '40vh'}`"
      >
        <FeedFilters
          v-model="filters"
        />

        <div class="col">
          <q-scroll-area
            :thumb-style="leftThumbScroll"
            :bar-style="leftBarScroll"
            class="full-height full-height"
          >
            <FeedLoading
              :is-loading="isLoading && startLoading"
            />

            <div v-observe-visibility="handleVisibilityStart" />

            <template v-for="item in items">
              <FeedItem
                :key="item.id"
                :id="item.id"
                :thumb="item.image"
                :image="item.images[0]"
                :numeration="item.numeration"
                :schedule-at="item.scheduleAt"
                :agency-label="item.agencyLabel"
                :material-date="item.materialDate"
                :schedule-title="item.scheduleTitle"
                :document-type-id="item.documentTypeId"
                :document-type-name="item.documentTypeName"
                :document-sub-type-name="item.documentSubTypeName"
              />
            </template>

            <div v-observe-visibility="handleVisibilityEnd" />

            <FeedLoading
              :is-loading="isLoading && !startLoading"
            />
          </q-scroll-area>
        </div>
      </div>

      <div class="col column full-height">
        <div class="col">
          <q-scroll-area
            :thumb-style="rightThumbScroll"
            :bar-style="rightBarScroll"
            class="full-height"
          >
            <router-view />
          </q-scroll-area>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import api from '@/aws/api'
import moment from '@/components/moment'
import FeedItem from './FeedItem'
import FeedLoading from './FeedLoading'
import FeedFilters from './FeedFilters'
import { ROUTE_FEEDS } from '../constants'
import { getThumbnailFeed } from '../utils'

import {
  genUrl,
  leftBarScroll,
  leftThumbScroll,

  rightBarScroll,
  rightThumbScroll
} from '@/components/documents'

export default {
  components: {
    FeedItem,
    FeedLoading,
    FeedFilters
  },

  data () {
    return {
      items: [],
      hasMore: true,
      isLoading: true,
      startLoading: true,

      leftBarScroll,
      leftThumbScroll,

      rightBarScroll,
      rightThumbScroll,

      contentHeight: 0,

      filters: {}
    }
  },

  mounted () {
    this.onLoadRef()
  },

  methods: {
    handleVisibilityStart (isVisibility) {
      if (!isVisibility) return
      if (this.isLoading) return
      Object.assign(this, { isLoading: true, startLoading: true })
      this.onLoadRef()
    },

    handleVisibilityEnd (isVisibility) {
      if (!isVisibility) return
      if (this.isLoading) return
      Object.assign(this, { isLoading: true, startLoading: false })
      this.onLoadRef()
    },

    myTweak (offset, height) {
      this.contentHeight = height - offset - 8
    },

    onLoadRef () {
      const vm = this
      const hasMore = vm.startLoading || vm.hasMore
      if (!hasMore) return

      const limit = 90
      const page = vm.startLoading ? 1 : Math.floor(vm.items.length / limit) + 1
      const timezone = moment.tz.guess()
      const now = moment().unix()

      api.getFeeds({ ...vm.filters, toScheduleAt: now, timezone, page, limit, columns }).then(({ rows, count }) => {
        const items = Array.isArray(vm.items) ? [...vm.items] : []
        const ids = items.map(({ id }) => id)

        for (const item of rows) {
          if (ids.includes(item.id)) return
          item.image = getThumbnailFeed(item, 'img_65_65')
          items.push(item)
        }

        const hasMore = items.length < count
        Object.assign(vm, { items, hasMore })
      }).catch((err) => (
        console.log(err)
      )).then(() => vm.$nextTick()).then(() => Object.assign(vm, { isLoading: false }))
    }
  },

  watch: {
    items: {
      deep: true,
      handler (items) {
        if (!items?.length) return
        if (this.$route.name !== ROUTE_FEEDS) return

        const firstFeed = items[0]
        this.$router.push(genUrl(firstFeed, false))
      }
    },

    filters: {
      deep: true,
      handler () {
        const vm = this
        Object.assign(vm, { items: [], startLoading: true, isLoading: true })
        vm.$nextTick().then(vm.onLoadRef)
      }
    }
  }
}

const columns = [
  'id',
  'images',
  'attachments',
  'numeration',
  'agencyLabel',
  'materialDate',
  'scheduleId',
  'scheduleAt',
  'scheduleTitle',
  'documentTypeId',
  'documentTypeName',
  'documentSubTypeName'
].join(',')

</script>
