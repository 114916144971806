<template>
  <q-input
    dense
    outlined
    v-model="text"
    hide-bottom-space
    :placeholder="placeholder"
    :readonly="readonly || disabled"
    :input-class="inputClass"
    :bg-color="bgColor"
    :color="errorColor"
    :suffix="suffix"
    :prefix="prefix"
    :type="type"
    :maxlength="maxlength"
    :debounce="debounce"
    :class="['bg-white myInput' , className]"
  >
    <template
      v-if="$scopedSlots['prepend']"
      #prepend="props"
    >
      <slot
        name="prepend"
        :props="props"
      />
    </template>
    <template
      v-if="$scopedSlots['append']"
      #append="props"
    >
      <slot
        name="append"
        :props="props"
      />
    </template>
  </q-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },

    state: {
      type: Object
    },

    bgColor: {
      type: String,
      default: 'white'
    },

    placeholder: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    className: {
      type: String
    },

    inputClass: {
      type: String
    },

    suffix: {
      type: String
    },

    prefix: {
      type: String
    },

    maxlength: {
      type: Number
    },

    type: {
      type: String,
      default: 'text'
    },

    debounce: {
      type: Number,
      default: 100
    }
  },

  data () {
    return {
      text: forceStr(this.value)
    }
  },

  computed: {
    errorColor () {
      const hasError = Boolean(String((this.state && this.state.dirty) || this.error ? this.error || this.state.error || '' : '').trim())
      return hasError ? 'red-8' : undefined
    }
  },

  watch: {
    text (text) {
      if (isEqual(text, this.value)) return
      this.$emit('input', forceStr(text))
    },

    value (value) {
      if (isEqual(this.text, value)) return
      this.text = forceStr(value)
    }
  }
}

const forceStr = (val) => String(val ?? '')
const isEqual = (source, target) => forceStr(source) === forceStr(target)
</script>
