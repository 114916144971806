<template>
  <q-btn
    flat
    dense
    size="0.7em"
    icon="mdi-eye-outline"
    @click="handlerView"
  >
    <q-tooltip>Clique aqui para visualizar o documento e suas ações</q-tooltip>
  </q-btn>
</template>

<script>
import { ROUTE_DOCUMENT_VIEW } from '../constants'

export default {
  props: {
    model: {
      required: true,
      type: Object
    }
  },

  methods: {
    handlerView () {
      this.$router.push({ name: ROUTE_DOCUMENT_VIEW, params: { id: this.model.id } })
    }
  }
}
</script>
