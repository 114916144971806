<template>
  <FieldText
    v-model="number"
    :label="label"
    :placeholder="placeholder"
    :maxlength="maxlength"
    :state="state"
    :prefix="prefix"
    :suffix="suffix"
    :disabled="disabled"
    :is-required="isRequired"
    class-wrapper="my-field-number"
  />
</template>

<script>
import FieldText from '@/components/forms/FieldText'

export default {
  components: {
    FieldText
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    prefix: {
      type: String
    },

    suffix: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    },

    maxlength: {
      type: Number,
      default: 6
    }
  },

  data () {
    return {
      number: this.splitNumbers(this.value)
    }
  },

  methods: {
    isEqual (source, target) {
      return this.splitNumbers(source) === this.splitNumbers(target)
    },

    splitNumbers: (val) => String(val || '').replace(/\D/g, ''),

    clearNumber (number) {
      this.$nextTick().then(() => {
        this.number = this.splitNumbers(number)
      })
    }
  },

  watch: {
    number (number) {
      if (this.splitNumbers(number) !== number) return this.clearNumber(number)
      if (this.isEqual(number, this.value)) return
      this.$emit('input', this.splitNumbers(number))
    },

    value (value) {
      if (this.isEqual(this.number, value)) return
      this.number = this.splitNumbers(value)
    }
  }
}
</script>
