<template>
  <label class="row filter-text relative-position full-width">
    <input
      class="col"
      :placeholder="placeholder"
      v-model="val"
      :disabled="disabled"
    >
    <SearchIcon
      class="absolute-right iconSearch"
      fill="#828282"
    />
  </label>
</template>

<script>
import SearchIcon from '@/assets/icons/Search.svg'

export default {
  components: {
    SearchIcon
  },

  props: {
    value: {},
    disabled: {},
    placeholder: {
      type: String,
      default: 'Buscar'
    }
  },

  data () {
    return {
      val: forceStr(this.value)
    }
  },

  mounted () {
    this.timeout = null
  },

  watch: {
    val (val) {
      const valLocal = forceStr(val)
      const valParent = forceStr(this.value)
      if (this.timeout) clearTimeout(this.timeout)
      if (valLocal !== valParent) this.timeout = setTimeout(() => this.$emit('input', valLocal), 350)
    },

    value (value) {
      const valLocal = forceStr(this.val)
      const valParent = forceStr(value)
      if (valLocal !== valParent) this.val = valLocal
    }
  }
}

const forceStr = (val) => String(val || '')

</script>

<style lang="sass" scoped>
  .filter-text
    input
      font-style: normal
      font-weight: normal
      font-size: 13px
      line-height: 18px
      color: #263238
      opacity: 0.9
      padding: 5px 25px 5px 5px
      border-radius: 5px
      border: 1px solid #CFD8DC

      ::placeholder
          color: #263238
          font-weight: 300
          opacity: 0.7

      &:hover
        border: 1px solid #BBDEFB

      &:focus
        border: 1px solid #0094D9
        outline: none

  .iconSearch
    margin-top: 7px
    margin-right: 6px
</style>
