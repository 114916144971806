<template>
  <div
    :class="`col-auto q-mb-xs bg-white border-radius-5 border-box ${options.length ? 'q-pt-sm q-pb-xs' : 'q-py-sm'} q-pl-sm q-pr-xs full-width`"
  >
    <div class="row items-center">
      <div class="col">
        <FieldSearch
          v-model="query.$"
          placeholder="Busca Rápida"
        />
      </div>

      <q-btn
        dense
        flat
        color="grey-7"
        class="col-auto"
        icon="mdi-filter-plus-outline"
      >
        <q-menu>
          <q-list
            dense
            style="min-width: 100px; max-width: 250px"
          >
            <template v-for="filter in filters">
              <q-item
                :key="`checkbox-${filter.name}`"
                clickable
                style="padding: 4px 16px 0 8px"
                @click="filter.selected = !filter.selected"
              >
                <q-item-section>
                  <q-checkbox
                    dense
                    size="sm"
                    v-model="filter.selected"
                    :label="filter.label"
                  />
                </q-item-section>
              </q-item>

              <CollapseTransition :key="`field-${filter.name}`">
                <q-item
                  v-show="filter.selected"
                  style="padding: 0 8px 4px 8px"
                >
                  <q-item-section>
                    <div class="row">
                      <div class="col-12 q-pb-sm">
                        <component
                          v-model="query[filter.name]"
                          :is="filter.component"
                          :debounce="filter.debounce"
                          :extra-name="filter.extraName"
                          :extra-field.sync="query[filter.extraField]"
                          :option-value="filter.optionValue"
                          :option-label="filter.optionLabel"
                          :api-options="filter.apiOptions"
                        />
                      </div>
                    </div>
                  </q-item-section>
                </q-item>
              </CollapseTransition>

              <q-separator
                :key="`separator-${filter.name}`"
              />
            </template>
          </q-list>
        </q-menu>
        <q-tooltip>Filtros Avançados</q-tooltip>
      </q-btn>
    </div>

    <div class="row">
      <template v-for="option in options">
        <q-chip
          square
          outline
          removable
          size="0.8em"
          color="primary"
          class="col-auto q-pl-xs q-pr-sm q-ml-none q-mr-xs"
          :key="option.key"
          @remove="removeFilter(option)"
        >
          <div class="col ellipsis">
            {{ option.label }}
          </div>
          <q-tooltip>{{ option.label }}</q-tooltip>
        </q-chip>
      </template>
    </div>
  </div>
</template>

<script>
import FieldSearch from '@/components/forms/FieldSearch'
import config from '@/modules/documents/config/list'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default {
  components: {
    FieldSearch,
    CollapseTransition
  },

  props: {
    value: {}
  },

  data () {
    const query = {}
    const filters = config().filters.filter(({ name }) => !['description', 'updatedAt'].includes(name)).map((filter) => ({ ...filter, selected: false }))

    return {
      query,
      filters
    }
  },

  mounted () {
    this.refreshQuery(this.value)
  },

  computed: {
    options () {
      const options = []
      const { query, filters } = this

      const list = Object
        .keys(query)
        .map((name) => filters.find(item => item.name === name))
        .filter(v => v)

      for (const filter of list) {
        if (!filter.selected) continue
        if (isEmpty(query[filter.name])) continue

        if (Array.isArray(query[filter.name])) {
          const optionValue = filter.optionValue || 'id'
          const optionLabel = filter.optionLabel || 'name'

          for (const option of query[filter.name]) {
            options.push({
              key: `${filter.name}-${option[optionValue]}`,
              name: filter.name,
              value: option[optionValue],
              label: option[optionLabel],

              optionValue,
              optionLabel
            })
          }

          continue
        }

        options.push({
          key: filter.name,
          name: filter.name,
          value: query[filter.name],
          label: `${filter.label}: ${query[filter.name]}`
        })
      }

      return options
    },

    mapFilters () {
      return this.filters.reduce((acc, item) => (
        { ...acc, [item.name]: item }
      ), {})
    }
  },

  methods: {
    removeFilter (option) {
      const query = { ...this.query }
      const isArray = Array.isArray(query[option.name])

      if (isArray) {
        query[option.name] = query[option.name].filter((item) => item[option.optionValue] !== option.value)
        if (!query[option.name].length) delete query[option.name]
      } else {
        delete query[option.name]
      }

      this.query = query

      const filter = this.filters.find((filter) => filter.name === option.name)
      if (filter) filter.selected = Boolean(query[option.name])
    },

    async refreshQuery (value) {
      const vue = this
      let filters = value ? { ...value } : {}
      const filterNames = Object.keys(filters)
      if (!filterNames.length) filters = null
      const query = vue.makeInput(vue.query)
      if (isEqual(query, value)) return

      for (const filterName of filterNames) {
        if (isEmpty(filters[filterName])) continue
        const filter = vue.mapFilters[filterName]
        const isArray = Boolean(filter.apiOptions)
        if (!isArray) continue
        filters[filterName] = await filter.apiOptions({ id: filters[filterName] }).then(({ rows }) => rows)
      }

      vue.query = filters || {}
    },

    makeInput (query) {
      const vue = this

      const input = Object.keys(query ?? {}).reduce((acc, filterName) => {
        if (isEmpty(query[filterName])) return acc
        const filter = vue.mapFilters[filterName]
        const optionValue = filter?.optionValue || 'id'
        const isArray = Boolean(filter?.apiOptions)
        const filterValue = isArray ? query[filterName].map((item) => item[optionValue]).join(',') : query[filterName]
        return { ...acc, [filterName]: filterValue }
      }, {})

      return Object.keys(input).length ? input : null
    }
  },

  watch: {
    query: {
      deep: true,
      handler (query) {
        const vue = this
        let value = vue.value ? { ...vue.value } : {}
        if (!Object.keys(value).length) value = null
        const input = vue.makeInput(query)
        if (isEqual(input, value)) return
        vue.$emit('input', input)
      }
    },

    value: {
      deep: true,
      handler (value) {
        this.refreshQuery(value)
      }
    },

    filters: {
      deep: true,
      handler (filters) {
        const query = this.query ? { ...this.query } : {}

        for (const filter of filters) {
          if (!filter.selected) {
            delete query[filter.name]
          }
        }

        if (!isEqual(this.query, query)) this.query = query
      }
    }
  }
}

const isEmpty = (val) => {
  if ([null, undefined, ''].includes(val)) return true
  if (Array.isArray(val) && !val.length) return true
  return typeof val === 'string' && !val.trim()
}

const isEqual = (val1, val2) => (
  JSON.stringify(val1) === JSON.stringify(val2)
)

</script>
