import { render, staticRenderFns } from "./FeedItem.vue?vue&type=template&id=48c6e300&"
import script from "./FeedItem.vue?vue&type=script&lang=js&"
export * from "./FeedItem.vue?vue&type=script&lang=js&"
import style0 from "./FeedItem.vue?vue&type=style&index=0&id=48c6e300&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QIntersection,QImg,QSpinnerIos});qInstall(component, 'directives', {Ripple});
