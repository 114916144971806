<template>
  <CollapseTransition>
    <div
      v-show="isLoading"
      class="col-12 row justify-center q-py-sm bg-white border-radius-8 border-box q-mt-sm"
    >
      <q-spinner-ios
        size="35px"
        color="primary"
        class="col-auto q-mx-auto"
      />
    </div>
  </CollapseTransition>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default {
  components: {
    CollapseTransition
  },

  props: {
    isLoading: {}
  }
}
</script>
