import api from '@/aws/api'
import moment from '@/components/moment'
import FieldText from '@/components/forms/FieldText'
import FieldNumber from '@/components/forms/FieldNumber'
import BtnViewDoc from '../components/BtnViewDoc'
import FieldDate from '@/components/forms/FieldDate'
import FilterSync from '@/modules/documents/components/FilterSync'
import FilterAsync from '@/modules/documents/components/FilterAsync'
import ScheduledColumn from '../components/ScheduledColumn'

export default () => ({
  actions: [
    BtnViewDoc
  ],

  pagination: {
    query: {}
  },

  columns: [{
    name: 'scheduleAtIcon',
    label: '',
    align: 'center',
    widthAuto: true,
    component: ScheduledColumn
  },
  {
    name: 'scheduleAt',
    label: 'Agendamento',
    align: 'center',
    widthAuto: true,
    format: (scheduleAt) => scheduleAt ? moment.unix(scheduleAt).format('DD/MM/YY HH:MM') : ''
  },
  {
    name: 'createdAt',
    label: 'Cadastro',
    align: 'center',
    widthAuto: true,
    format: (createdAt) => createdAt ? moment.unix(createdAt).format('DD/MM/YY HH:MM') : ''
  },
  {
    name: 'description',
    label: 'Assunto',
    class: 'col-max-400',
    align: 'left',
    format: (description, { degreeName, materialDate }) => {
      if (!description) description = ''
      return `${description.trim()}<br><small>${moment.unix(materialDate).format('DD/MM/YY')} Grau: <b>${degreeName}<b></small>`
    }
  }, {
    name: 'documentTypeName',
    label: 'Documento',
    align: 'left',
    widthAuto: true,

    format: (typeName, { documentTypeCode, documentSubTypeName }) => {
      let label = `${documentTypeCode} - ${typeName}`
      if (documentSubTypeName) label += `<br>${documentSubTypeName}`
      return label
    }
  }, {
    name: 'agencyLabel',
    label: 'Órgão Emissor',
    align: 'left',
    widthAuto: true
  }],

  filters: [{
    name: 'description',
    label: 'Nome / Título / Assunto',
    debounce: 350,
    component: FieldText
  }, {
    name: 'degreeId',
    label: 'Grau do Conteúdo',
    component: FilterSync,
    apiOptions: api.getDegrees
  }, {
    name: 'agencyId',
    label: 'Orgão Emissor',
    component: FilterAsync,
    apiOptions: api.getAgencies
  }, {
    name: 'regionId',
    label: 'Região',
    component: FilterAsync,
    apiOptions: api.getRegions
  }, {
    name: 'coreId',
    label: 'Núcleos',
    extraName: 'regionId',
    extraField: 'regionId',
    component: FilterAsync,
    apiOptions: api.getCores
  }, {
    name: 'materialDate',
    label: 'Data Documento',
    component: FieldDate
  }, {
    name: 'updatedAt',
    label: 'Data Cadastro / Atualização',
    component: FieldDate
  }, {
    name: 'documentTypeId',
    label: 'Tipo de Documento',
    component: FilterAsync,
    apiOptions: api.getDocumentTypes
  }, {
    name: 'documentSubTypeId',
    label: 'Subtipos de Documentos',
    extraName: 'documentTypeId',
    extraField: 'documentTypeId',
    component: FilterSync,
    apiOptions: api.getDocumentSubTypes
  }, {
    name: 'boardCouncilId',
    label: 'Conselhos',
    component: FilterSync,
    apiOptions: api.getBoardCouncil,
    optionLabel: 'prefix'
  }, {
    name: 'keywordId',
    label: 'Palavras Chaves',
    component: FilterAsync,
    apiOptions: api.getKeywords,
    optionLabel: 'word'
  }, {
    name: 'id',
    label: 'Código do Documento',
    component: FieldNumber
  }]
})
